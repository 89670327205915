import React, { useState, useEffect } from "react";
import axios from "axios";
import "tailwindcss/tailwind.css";
import Header from "../dashboard/Header";

const StaffForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    gender: "Male",
    designation: "",
    monthly_salary: "",
    daily_salary: "",
    total_paid_leaves: 0,
    used_paid_leaves: 0,
    hire_date: "",
    epf_number: "",
  });

  const [remainingPaidLeaves, setRemainingPaidLeaves] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Automatically calculate remaining paid leaves
    if (name === "total_paid_leaves" || name === "used_paid_leaves") {
      const totalLeaves =
        name === "total_paid_leaves"
          ? parseInt(value, 10)
          : formData.total_paid_leaves;
      const usedLeaves =
        name === "used_paid_leaves"
          ? parseInt(value, 10)
          : formData.used_paid_leaves;
      setRemainingPaidLeaves(totalLeaves - usedLeaves);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.indura.hemsidaavt.com/newstaff", // Replace with actual endpoint
        formData
      );
      alert("Form submitted successfully!");
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="bg-[#7CA9FF8A] font-[roboto]">
      <Header />
      <form
        onSubmit={handleSubmit}
        className="max-w-full font-[roboto] mx-auto p-6 bg-[#6f9ff98a]"
      >
        <h2 className="text-4xl font-semibold text-gray-800 mb-8 text-center">
          Staff Registration Form
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 text-black gap-6 gap-x-36">
          <div>
            <label className="block font-medium text-black">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
              placeholder="Full Name"
            />
          </div>

          <div>
            <label className="block font-medium text-black">Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div>
            <label className="block font-medium text-black">Designation</label>
            <input
              type="text"
              name="designation"
              value={formData.designation}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
              placeholder="Designation"
            />
          </div>

          <div>
            <label className="block font-medium text-black">
              Monthly Salary
            </label>
            <input
              type="number"
              name="monthly_salary"
              value={formData.monthly_salary}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
              placeholder="Monthly Salary"
            />
          </div>

          <div>
            <label className="block font-medium text-black">Daily Salary</label>
            <input
              type="number"
              name="daily_salary"
              value={formData.daily_salary}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
              placeholder="Daily Salary"
            />
          </div>

          <div>
            <label className="block font-medium text-black">
              Total Paid Leaves
            </label>
            <input
              type="number"
              name="total_paid_leaves"
              value={formData.total_paid_leaves}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
              placeholder="Total Paid Leaves"
            />
          </div>

          <div>
            <label className="block font-medium text-black">
              Used Paid Leaves
            </label>
            <input
              type="number"
              name="used_paid_leaves"
              value={formData.used_paid_leaves}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
              placeholder="Used Paid Leaves"
            />
          </div>

          <div>
            <label className="block font-medium text-black">
              Remaining Paid Leaves
            </label>
            <input
              type="number"
              value={remainingPaidLeaves}
              readOnly
              className="mt-1 p-2 focus:outline-none rounded-md block w-full bg-gray-100"
              placeholder="Remaining Paid Leaves"
            />
          </div>

          <div>
            <label className="block font-medium text-black">Hire Date</label>
            <input
              type="date"
              name="hire_date"
              value={formData.hire_date}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
            />
          </div>

          <div>
            <label className="block font-medium text-black">EPF Number</label>
            <input
              type="text"
              name="epf_number"
              value={formData.epf_number}
              onChange={handleChange}
              className="mt-1 p-2 focus:outline-none rounded-md block w-full"
              placeholder="EPF Number"
            />
          </div>
        </div>

        <div className="mt-12 p-4 text-xl flex justify-center items-center text-white">
          <button
            type="submit"
            className="w-1/3 bg-[#00307D] font-[roboto] py-2 px-4 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default StaffForm;
